import React from 'react'
import PropTypes from 'prop-types'

import { PageIntro, NewsletterForm, FormSection } from '#sections'
import { Layout, SectionTitle, AuctionBlock } from '#components'

export default function FutureAuctions({
  pageContext: { auctions, featuredImage },
}) {
  return (
    <Layout title="A list of our upcoming auctions">
      <PageIntro
        title="Future Auctions"
        description="A list of our upcoming auctions"
        preview={false}
        backgroundImage={featuredImage}
      />
      <div className="bg-white relative pb-55 md:pb-65">
        <div className="container pt-45 md:pt-55">
          <SectionTitle title="Future Auctions" />

          <div className="flex flex-wrap -mx-8 pt-28 md:pt-55">
            {auctions &&
              auctions.map((auction, index) => (
                <div
                  className={`px-8 w-full ${
                    index === 0 ? `mb-55 md:mb-80` : `mb-28 md:w-1/2 lg:w-1/3`
                  }`}
                  key={auction.AuctionDate}
                >
                  <AuctionBlock
                    auction={auction}
                    featured={index === 0}
                    future
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
      <NewsletterForm />
      <FormSection />
    </Layout>
  )
}

FutureAuctions.propTypes = {
  pageContext: PropTypes.object.isRequired,
}
